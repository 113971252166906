Vue.component('gs-sign-up-form', {
    template: `
        <v-card>
            <validation-observer ref="formSignUp">
                <v-form @submit.prevent="signUp" class="pa-10">
                    <v-card-title v-if="!$vuetify.breakpoint.xsOnly">Create a Customer account</v-card-title>
                    <v-card-subtitle>
                        Want to become a Delivery Pro?
                        <a href="/trucks/drivers/apply">Create a Delivery Pro account</a>
                    </v-card-subtitle>
                    <v-card-text>
                        <v-row>
                            <v-col>
                                <gs-v-text-field
                                    name="first name"
                                    rules="required"
                                    v-model.trim="userFirstName"
                                    label="First name">
                                </gs-v-text-field>
                            </v-col>
                            <v-col>
                                <gs-v-text-field
                                    name="last name"
                                    rules="required"
                                    v-model.trim="userLastName"
                                    label="Last name">
                                </gs-v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-text-field
                                    label="Email"
                                    v-model="userEmail"
                                    :rules="emailRules"
                                    required>
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <gs-v-text-field
                                    name="business name"
                                    label="Business Name"
                                    v-model="userBusinessName"
                                    :rules="businessNameValidationRules"
                                    :disabled="userIsNotBusiness">
                                </gs-v-text-field>
                                <v-checkbox
                                    class="mt-0"
                                    v-model="userIsNotBusiness"
                                    label="I don't have a business name"></v-checkbox>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <gs-v-text-field
                                    name="password"
                                    vid="password"
                                    rules="required|min:8"
                                    label="Create password"
                                    type="password"
                                    v-model.trim="userPassword">
                                </gs-v-text-field>
                            </v-col>
                            <v-col>
                                <gs-v-text-field
                                    name="password"
                                    rules="required|confirmed:password"
                                    label="Confirm password"
                                    type="password"
                                    v-model.trim="userConfirmPassword">
                                </gs-v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <gs-v-text-field
                                    name="phone"
                                    rules="required|phone_number_length"
                                    label="Mobile phone number"
                                    v-model.trim="userPhone"
                                    :mask="{phone: true, phoneRegionCode: 'US'}"
                                ></gs-v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <validation-provider
                                    name="country"
                                    rules="required"
                                    v-slot="{ errors }">
                                    <v-select
                                        :items="countryList"
                                        item-text="name"
                                        item-value="value"
                                        label="Country"
                                        v-model.trim="userCountry"
                                        @change="resetAddress"
                                        :error-messages="errors"></v-select>
                                </validation-provider>
                            </v-col>
                            <v-col>
                                <gs-v-text-field
                                    name="zip code"
                                    rules="required|numeric|min:5"
                                    ref="userZipCode"
                                    label="Zip code"
                                    v-model.trim="userZipCode"
                                    @change="getCityState"
                                    :mask="{numeric: true, blocks: [5]}">
                                </gs-v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-select
                                    :items="$store.state.user.cities"
                                    v-model="$store.state.user.city"
                                    :readonly="$store.state.user.cities.length === 1"
                                    :loading="loadingCityAndState"
                                    label="City">
                                </v-select>
                            </v-col>

                            <v-col>
                                <v-text-field
                                    :value="$store.state.user.state"
                                    label="State"
                                    readonly
                                    :loading="loadingCityAndState"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col v-if="GOOGLE_RECAPTCHA_WEBSITE_KEY">
                                <vue-recaptcha
                                    ref="signUpRecaptcha"
                                    @verify="recaptchaCallback"
                                    @expired="expiredRecaptchaCallback"
                                    size="invisible"
                                    :sitekey="GOOGLE_RECAPTCHA_WEBSITE_KEY">
                                </vue-recaptcha>
                            </v-col>
                        </v-row>
                        <v-btn block color="primary" type="submit" :loading="isLoading">Create a Customer account
                        </v-btn>
                    </v-card-text>
                </v-form>
            </validation-observer>
        </v-card>`,
    data: function() {
        return {
            userConfirmPassword: undefined,
            userIsNotBusiness: false,
            valid: true,
            lazy: true,
            loadingCityAndState: false,
            emailRules: [
                v => !!v || 'Email is required',
                v => /.+@.+\..+/.test(v) || 'Email must be valid',
            ],
            countryList: [],
            GOOGLE_RECAPTCHA_WEBSITE_KEY: googleRecaptchaWebsiteKey
        };
    },
    props: ['userData', 'isLoading'],
    components: {
        'vue-recaptcha': VueRecaptcha,
    },
    computed: {
        googleRecaptcha: {
            get: function() {
                return this.$store.state.signUpGoogleRecaptcha;
            },
            set: function(value) {
                this.$store.commit('changeSignUpGoogleRecaptcha', value);
            }
        },
        userFirstName: {
            get: function() {
                return this.$store.state.user.firstName;
            },
            set: function(value) {
                this.$store.commit('changeUserFirstName', value);
            }
        },
        userLastName: {
            get: function() {
                return this.$store.state.user.lastName;
            },
            set: function(value) {
                this.$store.commit('changeUserLastName', value);
            }
        },
        userPassword: {
            get: function() {
                return this.$store.state.user.password;
            },
            set: function(value) {
                this.$store.commit('changeUserPassword', value);
            }
        },
        userEmail: {
            get: function() {
                return this.$store.state.user.email;
            },
            set: function(value) {
                this.$store.commit('changeUserEmail', value);
            }
        },
        userPhone: {
            get: function() {
                return this.$store.state.user.phone;
            },
            set: function(value) {
                this.$store.commit('changeUserPhone', value);
            }
        },
        userCountry: {
            get: function() {
                return this.$store.state.user.country;
            },
            set: function(value) {
                this.$store.commit('changeUserCountry', value);
            }
        },
        userZipCode: {
            get: function() {
                return this.$store.state.user.zipCode;
            },
            set: function(value) {
                this.$store.commit('changeUserZipCode', value);
            }
        },
        userBusinessName: {
            get: function() {
                return this.$store.state.user.businessName;
            },
            set: function(value) {
                this.$store.commit('changeUserBusinessName', value);
            }
        },
        businessNameValidationRules: function() {
            return this.userIsNotBusiness ? '' : 'required';
        }
    },
    mounted() {
        this.countryList = countries
    },
    methods: {
        recaptchaCallback(response) {
            this.googleRecaptcha = response;
            this.$emit('submit-sign-up');
        },
        expiredRecaptchaCallback() {
            this.googleRecaptcha = null;
        },
        signUp: function() {
            this.$refs.formSignUp.validate().then(signUpFormValid => {
                if(signUpFormValid) {
                    this.$refs.signUpRecaptcha.execute();
                }
            });
        },
        resetAddress: function() {
            this.$refs.userZipCode.$refs.validationProvider.reset();
            this.userZipCode = "";
            this.$store.commit('changeUserCityState', {
                state: "",
                cities: ""
            });
        },
        getCityState() {
            this.$refs.userZipCode.$refs.validationProvider.validate().then(result => {
                if(result.valid) {
                    this.loadingCityAndState = true;
                    this.$store.dispatch('getCityStateFromZipCode').then(() => {
                        this.loadingCityAndState = false;
                    }).catch(() => {
                        this.loadingCityAndState = false;
                    });
                }
            });
        },
        signIn: function() {
            this.$emit('activate-sign-in-form');
        },
        closeDialog: function() {
            this.$refs.signUpRecaptcha.reset();
            this.googleRecaptcha = null;
            this.$emit('close');
        },
        getUserCard: function() {
            const vm = this;
            this.$store.dispatch('getUserCard').catch(function(response) {
                vm.$root.showToast(getErrorMessage(response));
            });
        }
    },
    watch: {
        userIsNotBusiness: function(newVal, oldVal) {
            console.log(oldVal, newVal)
            if(newVal === true) {
                this.userBusinessName = ''
            }
        }
    }
});
